<template>
    <footer>
        <p>POJO Softball</p>
        <p><a href="#">pojosoftball@gmail.com</a></p>
    </footer>
</template>
  
  <script>
  export default {
    name: 'BottomFooter',
  }
  </script>

  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    footer {
        text-align: center;
        padding: 3px;
        background-color: #FFFF00;;
        color: black;
        margin-top: 100px;
    }
  </style>
  